"use client"

import { createContext, useContext, useState } from "react"

interface ToastContextType {
  showToast: (message: string, type: "success" | "error") => void
}

const ToastContext = createContext<ToastContextType | undefined>(undefined)

export function ToastProvider({ children }: { children: React.ReactNode }) {
  const [isVisible, setIsVisible] = useState(false)
  const [message, setMessage] = useState("")
  const [type, setType] = useState<"success" | "error">("success")

  const showToast = (toastMessage: string, toastType: "success" | "error") => {
    setMessage(toastMessage)
    setType(toastType)
    setIsVisible(true)

    const timeoutId = setTimeout(() => {
      setIsVisible(false)
    }, 5000)
    return () => {
      clearTimeout(timeoutId)
    }
  }

  return (
    <ToastContext.Provider value={{ showToast }}>
      {children}
      {isVisible ? (
        <div className="fixed bottom-4 left-1/2 z-50 -translate-x-1/2">
          <div
            className={`flex min-w-[200px] justify-center rounded-lg px-6 py-3 ${
              type === "success"
                ? "bg-success-100 text-success-700"
                : "bg-danger-100 text-danger-700"
            }`}
          >
            {message}
          </div>
        </div>
      ) : null}
    </ToastContext.Provider>
  )
}

export const useToast = () => {
  const context = useContext(ToastContext)
  if (context === undefined) {
    throw new Error("useToast must be used within a ToastProvider")
  }
  return context
}
